import React from 'react'
import transbankwhite from '../../../Assets/transbankwhite.png'
import distribuidor from '../../../Assets/Capa_1.png'

function Footer() {
    return (
        <div className='w-full bg-[#009CDD] h-min flex justify-center py-4 bottom-0 sticky'>
            <div className='2xl:max-w-[1200px] md:max-w-[1000px] flex flex-col gap-10  p-5'>
                <img src={transbankwhite} alt='Mano con MPOS' className='self-start w-[200px]' />
                <div className='flex flex-col md:flex-row md:items-center justify-between gap-5 md:gap-0'>
                    <h3 className='font-bold text-4xl w-[70%] md:w-[55%] text-white leading-tight'>Ponte en contacto con nosotros para más información</h3>
                    <div className='border border-white rounded-full md:rounded-xl px-7 p-4 cursor-pointer'>
                        <a className='text-white text-5xl md:text-2xl font-normal' href='https://wa.me/56965824527'>Hablemos</a>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4  md:flex md:justify-between text-white'>
                    <div className='md:col-4 col-span-2'>
                        Avenida Andres Bello 2777<br />
                        Las Condes Metropolitana
                    </div>
                    <div className=' md:col-4 flex flex-col'>
                        <a href='https://www.instagram.com/soluciones_simple/'>Instagram</a>
                        <a href='https://www.facebook.com/comunidadsimple'>Facebook</a>
                    </div>
                    <div className=' md:col-4 md:text-right'>
                        Tel. 965824527<br />
                        Ayuda
                    </div>
                </div>
                <div className='w-full md:block flex justify-center items-center'>
                    <img src={distribuidor} alt='Mano con MPOS' className=' w-[150px]' />
                </div>
            </div>
        </div>
    )
}

export default Footer