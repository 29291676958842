import React, { useState } from 'react'
import { currencyFormater } from '../../Utils/currencyFormater'
import { AiFillInfoCircle } from 'react-icons/ai'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

function Simulador() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    React.useEffect(() => {
        scrollToTop()
    }, [])

    const [monto, setMonto] = useState('10000')
    const [comisionDebito, setComisionDebito] = useState(null)
    const [comisionPrepago, setComisionPrepago] = useState(null)
    const [comisionCredito, setComisionCredito] = useState(null)
    const [comisionDebitoExtranjero, setComisionDebitoExtranjero] = useState(null)
    const [comisionCreditoExtranjero, setComisionCreditoExtranjero] = useState(null)

    const createComisionDebito = () => {
        const tafifaFija = 61
        const tarifaVariableDebito = monto * 0.65 / 100
        const iva19 = Math.round((tafifaFija + tarifaVariableDebito) * 0.19)
        const comision = Math.round(Number(tafifaFija) + Number(tarifaVariableDebito) + Number(iva19))
        const total = Math.round(monto - comision)
        setComisionDebito({ comision, total })
    }
    const createComisionPrepago = () => {
        const tafifaFija = 61
        const tarifaVariablePrepago = monto * 1.15 / 100
        const iva19 = Math.round((tafifaFija + tarifaVariablePrepago) * 0.19)
        const comision = (Number(tafifaFija) + Number(tarifaVariablePrepago) + Number(iva19)).toFixed(2)
        const total = Math.round(monto - comision)
        setComisionPrepago({ comision, total })

    }

    const createComisionCredito = () => {
        const tafifaFija = 65
        const tarifaVariableCredito = monto * 1.38 / 100
        const iva19 = Math.round((tafifaFija + tarifaVariableCredito) * 0.19)
        console.log(iva19, tafifaFija, tarifaVariableCredito)
        const comision = Math.round(Number(tafifaFija) + Number(tarifaVariableCredito) + Number(iva19))
        const total = Math.round(monto - comision)
        setComisionCredito({ comision, total })

    }

    const createComisionDebitoExtranjero = () => {
        const tafifaFija = 61
        const tarifaVariableDebito = monto * 3.36 / 100
        const iva19 = Math.round((tafifaFija + tarifaVariableDebito) * 0.19)
        const comision = Math.round(Number(tafifaFija) + Number(tarifaVariableDebito) + Number(iva19))
        const total = Math.round(monto - comision)
        setComisionDebitoExtranjero({ comision, total })
    }

    const createComisionCreditoExtranjero = () => {
        const tafifaFija = 65
        const tarifaVariableCredito = monto * 2.53 / 100
        const iva19 = Math.round((tafifaFija + tarifaVariableCredito) * 0.19)
        const comision = Math.round(Number(tafifaFija) + Number(tarifaVariableCredito) + Number(iva19))
        const total = Math.round(monto - comision)
        setComisionCreditoExtranjero({ comision, total })
    }

    const handleComision = () => {
        createComisionDebito(monto)
        createComisionPrepago(monto)
        createComisionCredito(monto)
        createComisionDebitoExtranjero(monto)
        createComisionCreditoExtranjero(monto)
    }

    return (
        <div className='w-full h-min flex justify-center items-center py-20'>
            <div className='max-w-[1200px] md:max-w-[1000px] mx-auto flex flex-col md:flex-row justify-between w-full py-5  px-0 gap-5 text-[#545F71] h-full'>
                <div className='md:w-[40%] 2xl:w-auto flex flex-col items-center md:items-start p-5 gap-4 '>
                    <div className='w-full text-center'>
                        <h1 className='text-[#D00070] text-4xl md:text-4xl 2xl:text-5xl leading-10 font-semibold'>Conoce tu comisión</h1>
                        <div className='flex justify-between items-center p-4'>
                            <p className='text-sm mt-2'>Actividad económica</p>
                            <a className="my-anchor-element flex justify-center items-center text-xs">
                                ¿Que es?
                                <AiFillInfoCircle size={15}/>
                            </a>
                            <Tooltip anchorSelect=".my-anchor-element" place='bottom' effect='solid' className='bg-[#D00070] text-white rounded-xl p-5' >
                                <p className='text-xs'>Actividad economica: es toda aquella forma mediante<br></br> la que se produce, se intermedia y/o se vende un bien <br></br>o servicio destinado a satisfacer una necesidad o deseo.<br/> Un ejemplo de actividad economica seria,<br></br> arrendar una casa que tenemos en propiedad,<br></br> ya que estamos ofreciendo/vendiendo un servicio al inquilino.</p>
                            </Tooltip>
                        </div>
                        <p className='text-lg font-normal leading-normal my-3'>Selecciona el monto de tu venta</p>
                    </div>
                    <div className='w-full flex flex-col gap-5 mt-5'>
                        <h2 className='self-center text-[#D00070] text-6xl font-bold md:text-4xl 2xl:text-5xl'>${monto}</h2>
                        <input type="range" min="10000" max="1000000" value={monto} onChange={(e) => setMonto(e.target.value)} className='w-full h-2 accent-[#D00070] bg-gray-200 rounded-lg range appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:h-[10px]  [&::-webkit-slider-runnable-track]:bg-[#545F71] [&::-webkit-slider-thumb]:bg-[#D00070] [&::-webkit-slider-thumb]:top-[-10px] [&::-webkit-slider-thumb]:relative [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:h-[30px] [&::-webkit-slider-thumb]:w-[30px]  ' />
                        <p className='md:text-lg text-xs text-center md:text-left'>Monto minimo para simular: $10.000</p>
                        <button onClick={handleComision} className='bg-[#D00070] text-[white] rounded-xl p-4 px-14 md:px-10 text-lg self-center md:self-start '>Simular</button>
                    </div>
                </div>
                <div className={comisionDebito == null ? 'bg-[#EDEDED] hidden  rounded-3xl shadow-2xl p-5 md:flex justify-center items-center md:h-[450px] 2xl:h-[700px] md:w-[50%]' : 'md:w-[50%] bg-[#EDEDED] rounded-3xl shadow-2xl p-5'}>
                    {
                        comisionDebito == null ?
                            <p className='text-center text-base'>Completa la información de búsqueda para obtener resultados.</p>
                            :
                            <div className='flex flex-col gap-5 py-5'>
                                <h3 className='text-[#D00070] text-xl font-semibold'>Tarjetas nacionales</h3>
                                <div className='bg-white p-5 rounded-xl text-[#545F71] flex justify-between items-center'>
                                    <p className='text-xl md:text-2xl font-semibold'>Débito</p>
                                    <div>
                                        <p className='text-sm md:text-base'>Comisión por venta</p>
                                        <p className='text-base md:text-lg font-semibold'>Tu abono será</p>
                                    </div>
                                    <div>
                                        <p className='text-sm text-right'> -${currencyFormater(comisionDebito.comision)}</p>
                                        <p className='text-lg font-semibold'>${currencyFormater(comisionDebito.total)}</p>
                                    </div>

                                </div>
                                <div className='bg-white p-5 rounded-xl text-[#545F71] flex justify-between items-center'>
                                    <p className='text-xl md:text-2xl font-semibold'>Prepago</p>
                                    <div>
                                        <p className='text-sm md:text-base'>Comisión por venta</p>
                                        <p className='text-base md:text-lg font-semibold'>Tu abono será</p>
                                    </div>
                                    <div>
                                        <p className='text-sm text-right'> -${currencyFormater(comisionPrepago.comision)}</p>
                                        <p className='text-lg font-semibold'>${currencyFormater(comisionPrepago.total)}</p>
                                    </div>

                                </div>
                                <div className='bg-white p-5 rounded-xl text-[#545F71] flex justify-between items-center'>
                                    <p className='text-xl md:text-2xl font-semibold'>Crédito</p>
                                    <div>
                                        <p className='text-sm md:text-base'>Comisión por venta</p>
                                        <p className='text-base md:text-lg font-semibold'>Tu abono será</p>
                                    </div>
                                    <div>
                                        <p className='text-sm text-right'> -${currencyFormater(comisionCredito.comision)}</p>
                                        <p className='text-lg font-semibold'>${currencyFormater(comisionCredito.total)}</p>
                                    </div>

                                </div>
                                <h3 className='text-[#D00070] text-xl font-semibold'>Tarjetas Extranjeras</h3>
                                <div className='bg-white p-5 rounded-xl text-[#545F71] flex justify-between items-center'>
                                    <p className='text-xl md:text-2xl font-semibold'>Débito</p>
                                    <div>
                                        <p className='text-sm md:text-base'>Comisión por venta</p>
                                        <p className='text-base md:text-lg font-semibold'>Tu abono será</p>
                                    </div>
                                    <div>
                                        <p className='text-sm text-right'> -${currencyFormater(comisionDebitoExtranjero.comision)}</p>
                                        <p className='text-lg font-semibold'>${currencyFormater(comisionDebitoExtranjero.total)}</p>
                                    </div>
                                </div>
                                <div className='bg-white p-5 rounded-xl text-[#545F71] flex justify-between items-center'>
                                    <p className='text-xl md:text-2xl font-semibold'>Débito</p>
                                    <div>
                                        <p className='text-sm md:text-base'>Comisión por venta</p>
                                        <p className='text-base md:text-lg font-semibold'>Tu abono será</p>
                                    </div>
                                    <div>
                                        <p className='text-sm text-right'> -${currencyFormater(comisionCreditoExtranjero.comision)}</p>
                                        <p className='text-lg font-semibold'>${currencyFormater(comisionCreditoExtranjero.total)}</p>
                                    </div>
                                </div>
                                <p className='text-base text-[#545F71]'>Directo a tu cuenta de 1 a 2 días hábiles independiente de tu banco.</p>
                                <NavLink to="/" className='bg-[#D00070] text-white self-center p-4 rounded-xl'>Comienza a vender con Transbank</NavLink>
                                <div className='flex gap-5 items-center'>
                                    <AiFillInfoCircle fill='#545F71' size={30} />
                                    <ul className='text-xs list-disc w-[90%]'>
                                        <li className=''>Los valores mostrados son estimaciones basadas en los datos proporcionados y no constituyen ningún tipo de oferta o garantía</li>
                                        <li>Los montos estimados que se muestran incluyen IVA y es aplicado por cada transacción.</li>
                                    </ul>
                                </div>

                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default Simulador