import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Envio from '../../../Assets/Envio.png'
import Dropdown from '../../Utils/Dropdown'
import { currencyFormater } from '../../Utils/currencyFormater'
import { validateRut } from '@fdograph/rut-utilities'
import Select from '../../Utils/Select'
import Alert from '../../Utils/Alert'
import Trineo from '../../../Assets/trineo.png'
import { useSearchParams } from 'react-router-dom'
import { MdDiscount } from "react-icons/md";


function Formulario({ id, nombre, descuento, codigoDescuento, }) {
    const [params] = useSearchParams()
    const [token, setToken] = useState(null)
    const [productData, setProductData] = useState(null)
    const [region, setRegion] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [codigodescuento, setCodigodescuento] = useState(codigoDescuento)
    const [codigoInfo, setCodigoInfo] = useState(null)
    const [form, setForm] = useState({
        user_email: '',
        user_name: '',
        user_lastname: '',
        address: '',
        commune: '',
        region: '',
        user_phone: '',
        rut: '',
        shipping_option: 'free',
        number: '',
        dpto: '',
        referencias: '',
    })
    const [cantidad, setCantidad] = useState(1)
    const [productPrice, setProductPrice] = useState(0)
    const [regionSelected, setRegionSelected] = useState(null)
    const [comunaSelected, setComunaSelected] = useState(null)
    const [errorText, setErrorText] = useState(null)
    const [display, setDisplay] = useState(false)
    const [type, setType] = useState('error')




    const getProductData = async () => {
        try {
            const product = await axios.get(`${process.env.REACT_APP_API_URL}product/${id}`)
            setProductData(product.data.data[0])
            setProductPrice(product.data.data[0].price)
        } catch (err) {
            console.log(err)
        }
    }

    const getRegion = async () => {
        try {
            const region = await axios.get(`https://r57rbi7bwb.execute-api.sa-east-1.amazonaws.com/production/regions/`)
            setRegion(region.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    const submitForm = async () => {
        try {
            setDisabled(true)
            if (!validateName()) {
                setErrorText('Nombre o apellido no valido')
                setType('alert-error')
                setDisplay(true)
                setDisabled(false)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                return
            }
            if (!validateEmail()) {
                setErrorText('Email no valido')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setDisabled(false)
                return
            }
            if (!validatePhone()) {
                setErrorText('Telefono no valido, intenta con el formato 9 XXXX XXXX')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setDisabled(false)
                return
            }
            if (!verificateRut()) {
                setErrorText('Rut no valido, intenta con el formato 12345678-9')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setDisabled(false)
                return
            }
            if (form.address.length == 0 || comunaSelected.length == 0 || regionSelected.length == 0 || form.number.length == 0) {
                setErrorText('Verifique los campos de direccion')
                setType('alert-error')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 3000);
                setDisabled(false)
                return
            }
            if (codigoInfo) {
                const descuento = codigoInfo.porcenteje / 100
                const price = productData.price * cantidad
                console.log(productPrice.toFixed(3))
                const allForm = {
                    ...form,
                    user_address: {
                        calle: form.address,
                        numero: form.number,
                        dpto_ofc: form.dpto,
                        referencias: form.referencias,
                    },
                    region: regionSelected.region_name,
                    commune: comunaSelected,
                    products: {
                        name: nombre,
                        quantity: cantidad,
                    },
                    total: productPrice
                }

                const createCart = await axios.post(`${process.env.REACT_APP_API_URL}cart/create`, allForm)
                const response = await axios.post(`${process.env.REACT_APP_API_URL}cart/status`, { token: createCart.data.transbankUrl.token })
                const responseupdate = await axios.post(`${process.env.REACT_APP_API_URL}cart/update`, { token: createCart.data.transbankUrl.token, status: 'active', response_json: response.data })

                setToken(createCart.data.transbankUrl.token)
                setDisabled(false)
            } else {
                const allForm = {
                    ...form,
                    user_address: {
                        calle: form.address,
                        numero: form.number,
                        dpto_ofc: form.dpto,
                        referencias: form.referencias,
                    },
                    region: regionSelected.region_name,
                    commune: comunaSelected,
                    products: {
                        name: nombre,
                        quantity: cantidad,
                    },
                    total: productData.price * cantidad,
                }
                const createCart = await axios.post(`${process.env.REACT_APP_API_URL}cart/create`, allForm)
                setToken(createCart.data.transbankUrl.token)
                setDisabled(false)
            }

        } catch (err) {
            console.log(err)
        }

    }



    const getCodigoDescuento = async () => {
        try {
            const codigo = {
                codigo: codigodescuento.toUpperCase()
            }
            const getCodigo = await axios.post(`${process.env.REACT_APP_API_URL}descuentos/descuento`, codigo)
            if (getCodigo.status == 200) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'descuentoAplicado',
                    'codigoDescuento': getCodigo.data.codigo,
                    'valorDescuento': getCodigo.data.porcenteje,
                })
                setErrorText('Codigo de descuento aplicado con exito')
                setType('alert-success')
                setDisplay(true)
                setTimeout(() => {
                    setDisplay(false)
                }, 7000);
                setCodigoInfo(getCodigo.data)

            }
        } catch (err) {
            setCodigoInfo(null)
            setErrorText('Codigo de descuento no valido')
            setType('alert-error')
            setDisplay(true)
            setTimeout(() => {
                setDisplay(false)
            }, 7000);
            console.log(err)
        }
    }


    const validateName = () => {
        const regex = /^[a-zA-Z ]+$/
        const name = form.user_name
        const lastname = form.user_lastname
        if (regex.test(name) && regex.test(lastname)) {
            return true
        } else {
            return false
        }
    }

    const verificateRut = () => {
        return validateRut(form.rut)
    }

    const validateEmail = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        const email = form.user_email
        if (regex.test(email)) {
            return true
        } else {
            return false
        }
    }

    const validatePhone = () => {
        const regex = /^[0-9]+$/
        const phone = form.user_phone
        if (regex.test(phone) && phone.length === 9) {
            return true
        } else {
            return false
        }
    }


    const submiteForm = async () => {
        const formUrl = await document.forms['submit']
        formUrl.submit()
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleCodigoDescuento = (e) => {
        setCodigodescuento(e.target.value)
    }

    const codigoDescuentoProp = async () => {
        const oferta = params.get('oferta')
        if (oferta) {
            setCodigodescuento('MPOS60')
            return true
        } else {
            getCodigoDescuento()
            return false
        }

    }

    const execDescuento = async () => {
        const response = await codigoDescuentoProp()
        if (response) {
            getCodigoDescuento()
        }
    }

    useEffect(() => {
        if (token) {
            submiteForm()
        }
    }, [token])


    useEffect(() => {
        getProductData()
    }, [])

    useEffect(() => {
        setProductPrice(productData?.price * cantidad)
        if (codigoInfo) {
            const descuentoValue = codigoInfo.value
            const allDescuento = descuentoValue * cantidad
            setProductPrice(allDescuento)
        }
    }, [cantidad, codigoInfo])


    useEffect(() => {
        getRegion()
    }, [])

    useEffect(() => {
        execDescuento()
    }, [productData])




    return (
        <div className='text-[#545F71] w-full h-full '>
            {
                productPrice == NaN ? '' : <>
                    <Alert errorText={errorText} display={display} type={type} />
                    <p className='text-xl'>Estás solicitando 1 producto</p>
                    <h3 className='text-3xl md:text-5xl md:leading-10 font-semibold py-5'>{`Nuevo ${productData?.product_name}`}</h3>
                    <p className='text-xl font-semibold'>Total compra</p>
                    {
                        codigoInfo &&
                        <div className='flex flex-col justify-start items-start mt-5 '>
                            <p className='text-2xl font-semibold line-through '>{currencyFormater(productData?.price * cantidad)}</p>
                            <p className='text-7xl font-semibold '>{currencyFormater(productPrice)}</p>
                        </div>
                    }
                    {
                        !codigoInfo &&
                        <p className='text-6xl font-semibold py-5'>{currencyFormater(productPrice)}</p>
                    }

                    <div className='flex flex-col md:flex-row justify-between md:items-center my-10 gap-10'>
                        <div className='flex flex-col  gap-5'>
                            <p className='text-2xl md:text-3xl font-extrabold'>Cantidad</p>
                            <Dropdown quantity={cantidad} minvalue={1} maxvalue={50} onChange={setCantidad} />
                        </div>
                        <div className='md:w-[49%] flex flex-col'>
                            <p className='text-3xl font-extrabold'>Código Descuento</p>
                            <div className='flex flex-col md:flex-row items-center mt-5 gap-5 w-full'>
                                <input id='descuento' onChange={handleCodigoDescuento} type='text' name='codigoDescuento' value={codigodescuento} placeholder='Codigo de descuento' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3  w-full' />
                                <button onClick={getCodigoDescuento} className='bg-[#D00070] text-white border rounded-xl px-16 md:px-10 py-3 font-bold '>Aplicar</button>
                            </div>
                            <div className='bg-[#D00070] border  flex gap-3 items-center justify-center md:justify-start rounded-2xl md:w-80 mt-5 p-4'>
                                <MdDiscount size={30} className='text-[#F5F5F5]' />
                                <p className='text-[#F5F5F5] font-bold'>Descuento aplicado de {currencyFormater(codigoInfo?.porcenteje)}</p>
                            </div>

                        </div>
                    </div>

                    <div className='flex flex-col justify-start items-start md:mt-24'>
                        <p className='text-2xl md:text-3xl  font-extrabold'>Contacto</p>
                        <input id='email' onChange={handleSubmit} type='text' name='user_email' placeholder='Correo electronico*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 w-full md:w-[50%] mt-5' />
                        <div className='mt-10 w-full'>
                            <p className=' text-2xl md:text-3xl  font-extrabold'>Datos de envío</p>
                            <div className='flex flex-col md:flex-row w-full'>
                                <input id='name' onChange={handleSubmit} type='text' name='user_name' placeholder='Nombre*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 md:w-[50%] mt-5 md:mr-5' />
                                <input id='last_name' onChange={handleSubmit} type='text' name='user_lastname' placeholder='Apellido*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 md:w-[50%] mt-5' />
                            </div>
                            <input id='rut' type='text' maxLength={10} onChange={handleSubmit} name="rut" placeholder='RUT 12345678-9*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 w-full mt-5 ' />
                            {/* <input id='region' onChange={handleSubmit} type='text' name="region" placeholder='Region' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 w-[100%] mt-5' /> */}
                            {
                                region && <Select region={true} options={region} setRegionSelected={setRegionSelected} />
                            }
                            {
                                regionSelected && <Select region={false} options={regionSelected.districts} setRegionSelected={setRegionSelected} setComunaSelected={setComunaSelected} />
                            }
                            <div className='flex flex-col md:flex-row w-full'>
                                <input id='address' onChange={handleSubmit} type='text' name="address" placeholder='Calle*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 md:w-[50%] mt-5 md:mr-5' />
                                <input id='Numero' onChange={handleSubmit} type='number' name="number" placeholder='Numero de calle*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 md:w-[50%] mt-5' />
                            </div>
                            <div className='flex flex-col md:flex-row w-full'>
                                <input id='dpto' type='text' onChange={handleSubmit} name="dpto" placeholder='Dpto/oficina' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 md:w-[50%] mt-5 md:mr-5' />
                                <input id='referencia' onChange={handleSubmit} type='text' name="referencias" placeholder='Referencias' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 md:w-[50%] mt-5' />
                            </div>

                            <input id='user_phone' onChange={handleSubmit} type='text' maxLength={9} name="user_phone" placeholder='Telefono Ej:9 XXXX XXXX*' className='bg-[#F5F5F5] border border-[#545F71] rounded-full px-5 py-3 w-full mt-5' />
                            <div className='w-full border border-[#545F71] rounded-full px-5 py-3 mt-5 flex justify-between items-center'>
                                {
                                    form.region.length === 0 ?
                                        <p></p>
                                        :
                                        form.region.toLowerCase().includes('metropolitana') ?
                                            <p className='text-xs md:text-sm hidden md:block'>Compra hoy y recibe de 1 a 2 dias</p>
                                            :
                                            <p className='text-xs md:text-sm hidden md:block'>Compra hoy y recibe de 3 a 4 dias</p>
                                }
                                <p className='text-xs md:text-sm text-left '>Envio Gratis a todo Chile.</p>
                                <p className='text-xs md:text-sm font-bold'>Gratis</p>
                            </div>
                            <div className='flex flex-col md:flex-row mt-5 justify-between items-center'>
                                <div className='flex items-center font-normal gap-2 md:gap-0 justify-center'>
                                    <img src={Envio} className='md:w-[100px] w-[50px] ' />
                                    {/* <img src={Trineo} className='md:w-[100px] w-[100px] ' /> */}
                                    <p className='text-[#D00070] text-lg font-semibold'>Envío Gratuito a todo Chile</p>
                                </div>
                                <div>
                                    <button disabled onClick={submitForm} className='bg-[#D00070] text-white border rounded-xl px-10 py-3 font-bold mt-10  self-center'>Ir a Pagar</button>
                                    <form id='submit' className='hidden' method="post" action='https://webpay3g.transbank.cl:443/webpayserver/initTransaction'>
                                        <input type="hidden" name="token_ws" value={token} />
                                        <input type="submit" value="Ir a pagar" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
    )
}

export default Formulario