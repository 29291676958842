import React, { useEffect, useRef, useState } from 'react'
import Posimage1 from '../../../Assets/06_0000.png'
import Posimage2 from '../../../Assets/08_0000.png'
import Posimage3 from '../../../Assets/07_0000.png'
import Descuento6990 from '../../../Assets/tbk_blackfriday20-05.png'
import Signal from '../../../Assets/Signal .png'
import Network from '../../../Assets/network.png'
import Phone from '../../../Assets/phone.png'
import Envio from '../../../Assets/Envio.png'
import personaEscaleras from '../../../Assets/personaEscalera.png'
import { BiBluetooth } from 'react-icons/bi'
import { AiFillCreditCard } from 'react-icons/ai'
import { FaWandMagicSparkles, FaAnglesDown } from 'react-icons/fa6'
import "animate.css/animate.min.css";
import Formulario from '../../Sections/Formulario'
import ScrollAnimation from 'react-animate-on-scroll'
import { NavLink } from 'react-router-dom'


function PostDetailAB() {
    const shopRef = useRef()
    const simuladorRef = useRef()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    const handleClickGoToPay = () => {
        shopRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleClickGotoSimulador = () => {
        simuladorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }


    return (
        <div className='w-full flex flex-col'>
            <div className='bg-[#6D2077] h-min w-full md:pt-32 py-20 text-white'>
                <div className='hidden 2xl:max-w-[1200px] md:max-w-[1000px] mx-auto md:flex flex-col justify-between h-full'>
                    <div className='flex justify-between items-center'>

                        <div className='flex-col flex gap-5'>
                            <div className='bg-white border border-white 2xl:max-w-[150px] md:max-w-[120px] rounded-3xl p-2 scale transition-all shadow-2xl'>
                                <img src={Posimage1} alt='Logo' className='object-cover' />
                            </div>
                            <div className='bg-white border border-white 2xl:max-w-[150px] md:max-w-[120px] rounded-3xl p-2 scale transition-all shadow-2xl'>
                                <img src={Posimage2} alt='Logo' className='object-cover' />
                            </div>
                            <div className='bg-white border border-white 2xl:max-w-[150px] md:max-w-[120px] rounded-3xl p-2 scale transition-all shadow-2xl'>
                                <img src={Posimage3} alt='Logo' className='object-cover' />
                            </div>
                        </div>
                        {

                            <div className='bg-white border border-white rounded-3xl 2xl:max-w-[500px] md:max-w-[400px] scale transition-all shadow-2xl'>
                                <img src={Descuento6990} alt='Logo' className='object-cover' />
                            </div>
                        }
                        <div className='flex flex-col justify-between items-start w-96 gap-5'>
                            <h1 className='font-bold 2xl:text-7xl md:text-6xl leading-normal'>Máquina<br></br> Transbank</h1>


                            <div className='flex flex-col items-start gap-3'>
                                <p className='text-4xl font-semibold line-through'>{`$23.990`}</p>
                                <p className='text-8xl font-bold'>{`$17.990`}</p>
                            </div>



                            <button onClick={handleClickGoToPay} className='bg-white text-[#6D2077] p-4 px-[5.5rem] rounded-2xl  mt-6 transition-all'>
                                <p className='md:text-base 2xl:text-lg '>Comprar</p>
                            </button>
                        </div>
                    </div>
                    <ul className='flex  gap-5 w-full justify-between items-center mt-10'>
                        <li className='flex items-center gap-5'>
                            <img src={Signal} alt='Logo' className='w-[50px]' />
                            <p className='md:text-base 2xl:text-base'>Puedes hacer seguimiento de tus ventas</p>
                        </li>
                        <li className='flex items-center  gap-5'>
                            <img src={Phone} alt='Logo' className='w-[50px]' />
                            <p className='md:text-base 2xl:text-base'>Activa y configura el equipo tu mismo</p>
                        </li>
                        <li className='flex items-center  gap-5'>
                            <img src={Network} alt='Logo' className='w-[50px]' />
                            <p className='md:text-base 2xl:text-base'>Puedes llevarla a todas partes</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <div className=' h-min  flex gap-5 flex-col justify-center items-center md:hidden text-center'>
                        <h1 className='font-bold text-6xl leading-normal'>Máquina<br></br> Transbank</h1>
                        <div className='flex gap-5 p-5'>
                            <div className='flex-col flex gap-2'>
                                <div className='bg-white border border-white max-w-[100px] rounded-3xl p-2 scale transition-all'>
                                    <img src={Posimage1} alt='Logo' className='object-cover' />
                                </div>
                                <div className='bg-white border border-white max-w-[100px] rounded-3xl p-2 scale transition-all'>
                                    <img src={Posimage2} alt='Logo' className='object-cover' />
                                </div>
                                <div className='bg-white border border-white max-w-[100px] rounded-3xl p-2 scale transition-all'>
                                    <img src={Posimage3} alt='Logo' className='object-cover' />
                                </div>
                            </div>
                            {

                                <div className='bg-white border border-white rounded-3xl w-[300px]  scale'>
                                    <img src={Descuento6990} alt='Logo' className='object-cover w-full' />
                                </div>
                            }
                        </div>
                        <div className='flex flex-col justify-between text-start'>
                            <ul className='flex flex-col gap-5 px-5'>
                                <li className='flex items-center  gap-5'>
                                    <img src={Signal} alt='Logo' className='w-[50px]' />
                                    <p className='text-sm'>Puedes hacer seguimiento de tus ventas</p>
                                </li>
                                <li className='flex items-center  gap-5'>
                                    <img src={Phone} alt='Logo' className='w-[50px]' />
                                    <p className='text-sm'>Activa y configura el equipo tu mismo</p>
                                </li>
                                <li className='flex items-center  gap-5'>
                                    <img src={Network} alt='Logo' className='w-[50px]' />
                                    <p className='text-sm'>Puedes llevarla a todas partes</p>
                                </li>
                                <li onClick={handleClickGotoSimulador} className='text-center justify-center items-center flex gap-2'>
                                    <p className='md:text-base 2xl:text-lg'>Simulador de comisiones</p>
                                    <FaAnglesDown className='md:text-base 2xl:text-lg blob' />
                                </li>
                            </ul>
                            <button onClick={handleClickGoToPay} className='bg-white p-4 px-[3rem] rounded-2xl self-center mt-6'>
                                <p className='md:text-base 2xl:text-lg text-[#6D2077]'>Comprar</p>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div className='bg-[#7C3885] h-min w-full py-10 text-white md:bg-waves bg-wavesmovil bg-cover bg-center bg-no-repeat '>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] w-full mx-auto flex justify-center items-center gap-5'>
                    {/* <img src={Trineo} alt='Logo' className='w-[50px]' /> */}
                    <img src={Envio} alt='Logo' className='w-[50px]' />
                    <p className='md:text-base 2xl:text-lg'>Envío Gratuito a todo Chile en 24 hrs.</p>
                </div>
            </div>
            <div className='bg-white w-full h-min md:py-14'>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto flex flex-col md:flex-row justify-between w-full items-center h-full text-center p-5 md:p-0'>
                    <p className='text-[#D00070] font-bold text-5xl 2xl:text-6xl leading-normal hidden md:block'>
                        ¿Que beneficios tiene<br></br>
                        la maquinita MPOS?
                    </p>
                    <p className='text-[#D00070] font-bold text-[2.5rem] leading-normal md:hidden'>
                        ¿Que beneficios tiene
                        la maquinita MPOS?
                    </p>
                    <ul className='flex flex-col items-start justify-between text-[#545F71] gap-7 mt-5 md:mt-0'>
                        <li className='flex items-center gap-5'>
                            <div className='bg-[#6D2077] rounded-full p-2'>
                                <BiBluetooth fill='white' className='md:w-[50px] md:h-[50px] w-[30px] h-[30px]' />
                            </div>
                            <p className='2xl:text-xl md:text-lg'>Equipo bluetooth</p>
                        </li>

                        <li className='flex items-center gap-5'>
                            <div className='bg-[#6D2077] rounded-full p-2'>
                                <AiFillCreditCard fill='white' className='md:w-[50px] md:h-[50px] w-[30px] h-[30px]' />
                            </div>
                            <p className='2xl:text-xl md:text-lg'>Acepta diferentes medios de pago</p>
                        </li>
                        <li className='flex items-center gap-5'>
                            <div className='bg-[#6D2077] rounded-full p-2'>
                                <FaWandMagicSparkles fill='white' className='md:w-[50px] md:h-[50px] w-[30px] h-[30px]' />
                            </div>
                            <p className='2xl:text-xl md:text-lg'>La comisión más baja del mercado</p>
                        </li>
                    </ul>

                </div>
            </div>
            <div className='bg-white w-full h-min py-14'>
                <ScrollAnimation animateIn='animate__fadeInLeft' className='2xl:max-w-[1200px] md:max-w-[1000px] w-full mx-auto md:flex flex-col items-center justify-center bg-white p-5 md:p-0'>
                    {/* <h2 className='text-[#D00070] font-bold text-5xl 2xl:text-6xl'>Opiniones de clientes</h2> */}
                    {/* <div className='overflow-scroll md:overflow-hidden'>
                        <TablaComparacion />
                    </div> */}
                    <div ref={simuladorRef} className='flex flex-col md:flex-row justify-center items-center w-full gap-5 mt-10'>
                        <img src={personaEscaleras} alt='Logo' className='md:w-[250px] w-[100px]' />
                        <div className='flex flex-col justify-center md:items-start items-center gap-5  md:w-[40%] text-center md:text-left '>
                            <h3 className='text-5xl text-[#D00070] font-bold'>Simula tu comisión</h3>
                            <p className='md:text-left text-lg text-[#545F71] font-medium'>Nuestro simulador te ayudará a conocer la comisión que se te aplicará según tu actividad económica</p>
                            <NavLink to='/simulador' className='text-white bg-[#D00070] px-10 py-4 rounded-2xl'>
                                Ir al simulador
                            </NavLink>
                        </div>

                    </div>

                </ScrollAnimation>

            </div>
            <div ref={shopRef} className='bg-white w-full h-min py-14'>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] w-full mx-auto flex items-center justify-start bg-white p-5 md:p-0'>
                    <Formulario id={8} nombre={'Mobile POS con Garantía base'} />
                </div>
            </div>
        </div>
    )
}

export default PostDetailAB