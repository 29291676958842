import React, { useEffect, useState } from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'

function Dropdown({ quantity,maxvalue, minvalue, value, onChange }) {
    const [DropdownActive, setDropdownActive] = useState(false)
    const [items, setItems] = useState([])


    const showItems = () => {
        var items = []
        for (var i = minvalue; i < maxvalue + 1; i++) {
            items.push(i)
        }
        setItems(items)
    }

    useEffect(() => {
        showItems()
    }, [])



    return (
        <div className=' md:w-36 font-medium relative'>
            <div onClick={() => setDropdownActive(!DropdownActive)} className='p-2 border border-black rounded-full justify-between flex items-center w-full '>
                <p className='text-{#9BA5B7] text-lg mx-auto '>
                    {quantity}
                </p>
                <AiOutlineArrowDown size={22} />
            </div>
            <ul className='mt-2 overflow-y-scroll md:absolute w-full max-h-40 md:max-h-20'>
                {
                    DropdownActive && (
                        <div className='p-2 text-sm '>
                            {
                                items.map((item, index) => {
                                    return (
                                        <li key={index} onClick={() => { onChange(item); setDropdownActive(false) }} className='p-2 border-b border-black text-center cursor-pointer hover:bg-gray-200'>
                                            {item}
                                        </li>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </ul>

        </div>

    )
}

export default Dropdown