import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import mposTransbank from '../../../Assets/mpos-transbank-cuadrado.png'
import mposCompraaqui from '../../../Assets/mpos-compraqui-cuadrado.png'
import mposumup from '../../../Assets/mpos-sumup-cuadrado.png'
import mpospointmini from '../../../Assets/mpos-pointmini-cuadrado.png'
import mposgetnet from '../../../Assets/mpos-getnet-cuadrado.png'

function TablaComparacion() {
    return (

        <table className=" text-sm text-left text-[#545F71] overflow-scroll ">
            <thead className="text-lg text-[#545F71] ">
                <tr>
                    <th scope="col" className="px-6 py-3 text-center">
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                        Transbank
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                        Compraquí
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                        Getnet
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                        SumUp
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                        Mercado
                        Pago
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                    </th>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <img src={mposTransbank} alt='Mano con MPOS' className='w-[70px]' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <img src={mposCompraaqui} alt='Mano con MPOS' className='w-[70px]' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <img src={mposgetnet} alt='Mano con MPOS' className='w-[70px]' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <img src={mposumup} alt='Mano con MPOS' className='w-[70px]' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <img src={mpospointmini} alt='Mano con MPOS' className='w-[70px]' />
                        </div>
                    </td>
                </tr>


                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Compatible con iOS
                    </th>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Compatible con Android
                    </th>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Compatible con Huawei
                    </th>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>

                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>

                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>

                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>

                        </div>
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Tiempo de abono
                    </th>
                    <td className="px-6 py-4 text-center">
                        24 horas
                        hábiles
                        (débito y
                        prepago)<br />
                        48 horas
                        hábiles
                        (crédito)
                        (Corte a las
                        14:00)

                    </td>
                    <td className="px-6 py-4 text-center">
                        1 día hábil
                    </td>
                    <td className="px-6 py-4 text-center">
                        72
                        horas hábiles

                    </td>
                    <td className="px-6 py-4 text-center">
                        1 a 2 días
                        hábiles

                    </td>
                    <td className="px-6 py-4 text-center">
                        Inmediato en
                        cuenta
                        Mercado Pago
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Precio del equipo
                    </th>
                    <td className="px-6 py-4 text-center">
                        $17.990

                    </td>
                    <td className="px-6 py-4 text-center">
                        $19.990
                    </td>
                    <td className="px-6 py-4 text-center">
                        $24.990

                    </td>
                    <td className="px-6 py-4 text-center">
                        $29.990

                    </td>
                    <td className="px-6 py-4 text-center">
                        $29.990
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Permite propinas
                    </th>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>

                    </td>
                    <td className="px-6 py-4 text-center">

                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>
                    </td>
                    <td className="px-6 py-4 text-center">
                        <div className='flex justify-center items-center'>
                            <AiOutlineCheck size={40} color='#545F71' />
                        </div>

                    </td>
                    <td className="px-6 py-4 text-center">

                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Costo mantencion
                    </th>
                    <td className='px-6 py-4 text-center'>
                        <p>$0</p>
                    </td>
                    <td className='px-6 py-4 text-center'>
                        <p>$0</p>

                    </td>
                    <td className='px-6 py-4 text-center'>
                        <p>$0</p>

                    </td>
                    <td className='px-6 py-4 text-center'>
                        <p>$0</p>

                    </td>
                    <td className='px-6 py-4 text-center'>
                        <p>$0</p>

                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Comisión
                        tarjetas de
                        crédito

                    </th>
                    <td className='px-6 py-4 text-center'>
                        1,42% + $63 + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,9% IVA
                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,79% + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,9% + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,95% + IVA
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Comisión
                        tarjetas de
                        debito

                    </th>
                    <td className='px-6 py-4 text-center'>
                        0,63% + $59 + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,9% + IVA
                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,79% + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,9% + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,95% + IVA
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Comisión
                        tarjetas de
                        prepago

                    </th>
                    <td className='px-6 py-4 text-center'>
                        0,63% + $59 + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,9% IVA
                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,79% + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,9% + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,95% + IVA
                    </td>
                </tr>
                <tr className="bg-white border-b text-[#545F71] ">
                    <th scope="row" className="px-6 py-4 font-bold text-[#545F71] text-lg ">
                        Comisión
                        tarjetas de
                        extranjeras

                    </th>
                    <td className='px-6 py-4 text-center'>
                        No varía

                    </td>
                    <td className='px-6 py-4 text-center'>
                        No varía
                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,99% + $250 + IVA

                    </td>
                    <td className='px-6 py-4 text-center'>
                        No varía

                    </td>
                    <td className='px-6 py-4 text-center'>
                        2,95% + IVA
                    </td>
                </tr>
            </tbody>
        </table>

    )
}

export default TablaComparacion