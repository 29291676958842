import React, { useEffect, useState } from 'react'
import logoPink from '../../../Assets/Logo.png'
import LogoBlanco from '../../../Assets/1-Transbank_Color_FB_800px 1.png'
import { NavLink, useLocation } from 'react-router-dom'
import useScrollDirection from '../../Utils/Scroll'
import { FaWhatsapp } from 'react-icons/fa6'

function Navbar() {
    const location = useLocation();
    const [nav, setNav] = useState(true);
    const [color, setColor] = useState('#D00070');
    const [bgColor, setBgColor] = useState('bg-white');
    const [iconColor, setIconColor] = useState('#D00070');
    const [logo, setLogo] = useState(logoPink);
    const scroll = useScrollDirection()

    const handleNavbar = () => {
        setNav(!nav);
    }


    const bgNavbar = () => {
        const navbar = document.querySelector('.Navbar');
        if (window.location.pathname === '/compra-detail') {
            navbar.classList.add('hidden')
        } else {
            navbar.classList.remove('hidden')
        }
    }

    const changeColor = () => {
        if (window.location.pathname === '/comparativa') {
            setColor('[white]')
            setBgColor('bg-[#D00070]')
            setIconColor('#FFF')
            setLogo(LogoBlanco)
            
        }else{
            setColor('[#D00070]')
            setBgColor('bg-white')
            setIconColor('#D00070')
            setLogo(logoPink)
            
        }
    }






    useEffect(() => {
        bgNavbar();
    }, [])

    useEffect(() => {
        changeColor()
    }, [location])





    return (
        <div className={`fixed w-full top-0 h-24 max-w-[100%]  bg-transparent z-50 Navbar transition-all`} role="navigation">
            <div className={`flex justify-between items-center 2xl:max-w-[1200px] md:max-w-[1000px] text-${color} mx-auto p-4 px-6 ${bgColor} md:rounded-full Navbar `}>
                <a href='/'>
                    <img src={logo} alt='logo' className='md:me-20 w-40' />
                </a>
                <ul className=' hidden md:flex justify-center gap-[50px] items-center w-full text-sm'>
                    <li id='mposheader'>
                        <NavLink to='/pos-detail'>MPOS</NavLink>
                    </li>
                    {/* <li id='emprendeheader'>
                        <NavLink to='/emprende-detail'>Pack Emprende</NavLink>
                    </li> */}
                    <li>
                        <NavLink to='https://publico.transbank.cl/pre-activatuequipo'>Activar mi MPOS</NavLink>
                    </li>
                    <li className={`border-2 border-${color} p-1 px-6 rounded-2xl`}>
                        <NavLink to='https://privado.transbank.cl/'>Mi Cuenta</NavLink>
                    </li>
                    <li className='text-black font-normal flex gap-3 items-center'>
                        <FaWhatsapp size={30} color={iconColor} />
                        <NavLink className={`text-${color}`} to='https://wa.me/56996569825'>Contactanos al Whatsapp</NavLink>
                    </li>
                </ul>
                <div onClick={handleNavbar} className='block md:hidden z-50'>
                    {!nav ? <div>
                        <FaWhatsapp size={30} color={iconColor} />
                    </div> : <div><p className='font-normal text-xl'>Menu</p></div>}
                </div>
                <div className={!nav ? 'fixed left-0 top-0 w-[100%] h-full  border-r-gray-900 bg-white ease-in-out duration-500 ' : 'fixed left-[-100%]'}>
                    <img src={logo} alt="Logo" className="h-13 m-4 mx-6 w-40" />
                    <div className='flex flex-col justify-between h-[70%]'>
                        <ul className=' p-4 flex flex-col gap-6 font-extrabold tracking-widest'>
                            <li id='mpossidebar' className=' text-3xl'>
                                <NavLink to="/pos-detail" onClick={handleNavbar} exact>Mpos</NavLink>
                            </li>
                            <li className='text-3xl '>
                                <NavLink to="https://publico.transbank.cl/pre-activatuequipo" onClick={handleNavbar} exact>Activar Mpos</NavLink>
                            </li>
                            <li className=' text-3xl '>
                                <NavLink to='https://privado.transbank.cl/' onClick={handleNavbar} exact>Mi Cuenta</NavLink>
                            </li>
                        </ul>
                        <div className='font-bold text-xl flex gap-3 items-center self-center'>
                        <FaWhatsapp size={30} color={iconColor} />
                            <NavLink to='https://wa.me/56996569825'>Contactanos al Whatsapp</NavLink>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Navbar