import React from 'react'
import Formulario from '../../Sections/Formulario'
import Precio from '../../../Assets/ImagenDescuento.png'
import PrecioMovil from '../../../Assets/ImagenDescuentoMovil.png'

function DescuentoScreen() {
  return (
    <div className='w-full  md:max-w-[1400px] h-min  md:h-screen md:py-10 py-24 2xl:px-0 md:px-10 mx-auto overflow-hidden md:p-5'>
      <div className='w-full flex flex-col md:flex-row justify-between items-center h-full gap-5'>
        <div className='sticky'>
          <img src={Precio} alt='Precio' className='2xl:w-[400px] md:w-[400px] object-contain self-center rounded-2xl hidden md:block' />
          <img src={PrecioMovil} alt='Precio' className='2xl:w-[400px] md:w-[400px] object-contain self-center  block md:hidden' />
        </div>
        <div className='overflow-y-scroll  md:py-20 md:px-10 md:h-[90%] md:w-[80%] p-5'>
          <Formulario id={8} descuento={true} nombre={'Mobile POS con Garantía base'} codigoDescuento={'EMPRENDE'} />
        </div>

      </div>

    </div>
  )
}

export default DescuentoScreen