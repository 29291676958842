import React from 'react'

function Alert({errorText,display, type}) {
    return (
        <div className={display ? 'toast' : 'toast hidden'}>
            <div class={`alert ${type} text-white`}>
                <span>{errorText}</span>
            </div>
        </div>
    )
}

export default Alert