import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./Components/Screens/HomeScreen";
import Navbar from "./Components/Sections/Navbar";
import EmprendeDetail from "./Components/Screens/EmprendeDetail";
import CompraStatus from "./Components/Screens/CompraStatus";
import Footer from "./Components/Sections/Footer";
import Simulador from "./Components/Screens/Simulador";
import PostDetailAB from "./Components/Screens/PosDetailAB";
import DescuentoScreen from "./Components/Screens/DescuentoScreen";
import ComparativaScreen from "./Components/Screens/ComparativaScreen";


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/pos-detail" element={<PostDetailAB />} />
        {/* <Route path="/emprende-detail" element={<EmprendeDetail />} /> */}
        <Route path="/compra-detail" element={<CompraStatus />} />
        <Route path="/simulador" element={<Simulador />} />
        <Route path="/comparativa" element={<ComparativaScreen />} />
        <Route path="/pos-detail-2" element={<DescuentoScreen />} />
        <Route path="*" element={<PostDetailAB />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
