export const currencyFormater = (value,digits=0) => {
    const formatter = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits:0,
        
    })
    const result = formatter.format(value).replace('CLP', '').replace(',','.');

    return result;
    
}