import React, { useEffect } from 'react'
import ManoPos from '../../../Assets/Mano MPOS.png'
import posInfo from '../../../Assets/mpos-slide-1.png'
import { NavLink } from 'react-router-dom'
import "animate.css/animate.min.css";
import AppT from '../../../Assets/App.png'
import ComparativaToast from '../../Utils/ComparativaToast';






function HomeScreen() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    return (

        <div className='flex flex-col bg-white text-white'>

            <div className='w-full bg-[#6D2077] 2xl:bg-home bg-no-repeat 2xl:bg-center bg-right  h-min md:h-screen 2xl:h-screen flex justify-center items-center'>
                <div className='max-w-[1000px] 2xl:max-w-[1200px] mx-auto w-full flex flex-col-reverse md:flex-row-reverse justify-between md:gap-5 2xl:justify-between items-center md:pt-28 2xl:pt-10 p-5 py-16 md:px-0 '>
                    <div className='flex justify-center items-center '>
                        <img src={ManoPos} className='2xl:w-[600px] md:w-[410px] w-[500px] mt-10 md:mt-0 blob' />
                    </div>
                    <div className='gap-10 flex flex-col items-center justify-center md:items-start mt-16 md:mt-0'>
                        <p className='text-5xl md:text-5xl 2xl:text-6xl font-semibold text-center md:text-left 2xl:leading-normal md:w-96 2xl:w-[25rem]'><span className='font-bold'>¡Tu maquinita </span>al mejor precio!</p>
                        <p className='text-sm text-center md:text-start md:text-lg 2xl:text-2xl font-normal w-full'>La comisión más baja del mercado y con envío gratis </p>
                        <p className='text-8xl md:text-8xl 2xl:text-9xl font-bold'>$17.990</p>
                        <div className='flex flex-row-reverse md:flex-row gap-5 '>
                            <NavLink to='pos-detail' className='btn bg-[#D00070] text-white border-none hover:bg-white hover:text-[#D00070] '>
                                Compra tu MPOS
                            </NavLink>
                            {/* <NavLink to='emprende-detail' className='btn bg-[#D00070] text-white border-none hover:bg-white hover:text-[#D00070] w-[48%] '>
                                Compra tu Pack emprende
                            </NavLink> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full md:max-w-[1000px] 2xl:max-w-[1200px] mx-auto flex flex-col gap-20 h-min py-10 p-5 md:px-0'>
                <div className='text-[#D00070] bg-[#F5F5F5] w-full flex flex-col md:flex-row md:justify-between items-center md:px-[100px] md:py-[51px] p-10 rounded-2xl  gap-10 md:gap-0 shadow-2xl'>
                    <h2 className='text-5xl md:text-7xl 2xl:text-8xl font-bold text-center'>Equipo bluetooth</h2>
                    <img src={posInfo} className='md:w-[400px] w-[500px]' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 justify-items-center items-center gap-5 md:gap-0 '>
                    <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Transbank/mpos_1_4x.webp'} className='md:w-[300px] w-[250px] scale transition-all' />
                    <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Transbank/mpos_2_4x.webp'} className='md:w-[300px] w-[250px] scale transition-all' />
                    <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Transbank/mpos_3_4x.webp'} className='md:w-[300px] w-[250px] scale transition-all' />
                </div>
                {/* <div className='self-start text-[#D00070] '>
                    <p className='text-3xl md:text-6xl 2xl:text-7xl font-bold text-start'>Prefiere Transbank.<br /> Una solución y medio de pago para cada tipo de negocio</p>
                </div> */}
            </div>
            <div className='w-full bg-[#F5F5F5] h-min flex flex-col justify-center items-center'>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto w-full flex flex-col md:flex-row justify-between md:items-center p-5 md:py-20 py-10 md:px-0'>
                    <div className='flex flex-col md:w-[60%] gap-5'>
                        <h3 className='text-[#D00070] text-5xl md:text-6xl font-bold leading-tight text-left'>Descarga la App Transbank, visualiza tus ventas y abonos</h3>
                        <p className='text-[#545F71] text-xl text-left'>Si ya eres cliente y parte del mundo Transbank, te invitamos a descargar nuestra App, donde podrás revisar tus ventas, abonos y el crecimiento de tu negocio desde celulares.</p>
                    </div>
                    <img src={AppT} className='md:w-[400px] w-[700px] mt-5 md:mt-0' />
                </div>

            </div>
        </div>
    )
}

export default HomeScreen