import { useState, useEffect } from 'react';

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
    useEffect(() => {
        let lastScrollY = 0;
        console.log(lastScrollY)
        // function to run on scroll
        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            if(scrollY == lastScrollY){
                setScrollDirection('home')
            }else{
                setScrollDirection('other')
            }

        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection]); // run when scroll direction changes

    return scrollDirection;
};



export default useScrollDirection;