import React from 'react'

function Select({ options, region, setRegionSelected, setComunaSelected }) {

    const handleChange = (e) => {
        if (region) {
            const region = options.find((option) => option.region_code == e.target.value)
            setRegionSelected(region)
        } else {
            setComunaSelected(e.target.value)
        }
    }

    return (

        <select onChange={handleChange} className="select border border-[#545F71] focus:border-[#545F71] w-full bg-[#F5F5F5]  mt-5 rounded-full">

            <option disabled selected>{region ? 'Selecciona una region*' : 'Selecciona una comuna*'}</option>
            {
                region ?
                    options.map((optionRegion) => {
                        return <option value={optionRegion.region_code}>{optionRegion.region_name}</option>
                    })
                    :
                    options.filter((commune) => commune.district_name !== 'Isla  de Pascua').map((optionComuna) => {
                        return <option value={optionComuna.district_name}>{optionComuna.district_name}</option>
                    })
            }

        </select>
    )
}

export default Select