import React from 'react'
import TablaComparacion from '../../Sections/TablaComparacion'
import { NavLink } from 'react-router-dom'

function ComparativaScreen() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    React.useEffect(() => {
        scrollToTop()
    }, [])


    return (
        <div className='w-full h-min py-32 flex justify-center items-center flex-col'>
            <div className='w-full 2xl:max-w-[1200px] max-w-[1000px] mx-auto text-center flex items-center flex-col gap-5 p-5 md:p-0'>
                <h1 className='text-5xl color-transbank font-bold'>¿Por Qué elegir nuestra MPOS?<br></br>
                    <span className='text-3xl font-normal'>Compárala con el mercado</span> </h1>
                <div className='overflow-scroll md:overflow-hidden w-full'>
                    <TablaComparacion />
                </div>
                <NavLink to='/pos-detail' className=' btn text-white  bg-[#D00070] rounded-2xl text-xl py-3 h-min md:self-end'>Vende con la menor comisión</NavLink>
            </div>

        </div>
    )
}

export default ComparativaScreen