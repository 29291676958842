import React, { useEffect, useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import mano from '../../../Assets/mano 1 (1).png'
import { TailSpin } from 'react-loader-spinner'

function CompraStatus() {

    const [tbkToken, setTbkToken] = useSearchParams()
    const [token, setToken] = useState(null)
    const [status, setStatus] = useState(null)
    const [cart, setCart] = useState(null)
    const [operationStatus, setOperationStatus] = useState(null)
    const [tokenModel, setTokenModel] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (tbkToken.get('TBK_TOKEN')) {
            setToken(tbkToken.get('TBK_TOKEN'))
            setTokenModel('TBK_TOKEN')
        }
        if (tbkToken.get('token_ws')) {
            setToken(tbkToken.get('token_ws'))
        }
    }, [])

    useEffect(() => {
        if (token?.length > 0) {
            getCompraStatus()
        }
    }, [token])

    const getCompraStatus = async () => {
        try {
            setLoading(true)
            if (tokenModel !== 'TBK_TOKEN') {
                const commitCart = await axios.post(`${process.env.REACT_APP_API_URL}cart/commit`, { token_ws: token })
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}cart/status`, { token: token })
            setStatus(response.data)

            if (response.data.response_code == 0) {
                const responseupdate = await axios.post(`${process.env.REACT_APP_API_URL}cart/update`, { token: token, status: 'completed', response_json: response.data })
                setOperationStatus('completed')
            } else {
                const responseupdate = await axios.post(`${process.env.REACT_APP_API_URL}cart/update`, { token: token, status: 'canceled', response_json: response.data })
                setOperationStatus('canceled')

            }
            const responsecart = await axios.post(`${process.env.REACT_APP_API_URL}cart/carrito`, { token: token })
            setCart(responsecart.data)

            if (responsecart.data.cart_status == 'completed' && responsecart.data.datalayer !== true) {
                const data = {
                    order_code: `Venta-${responsecart.data.buyOrder}`,
                    shipping: "Envio Flex",
                    first_name: responsecart.data.user_name,
                    last_name: responsecart.data.user_lastname,
                    email: responsecart.data.user_email,
                    address: responsecart.data.user_address.calle + responsecart.data.user_address.numero,
                    address_detail: responsecart.data.user_address.dpto_ofc,
                    address_extra_info: responsecart.data.user_address.referencias,
                    city: responsecart.data.commune,
                    phone: responsecart.data.user_phone,
                    products: [
                        {
                            sku: "MPOS-1",
                            quantity: responsecart.data.products.quantity,
                            price: responsecart.data.total
                        }],
                }
                const sendEmail = await axios.post(`${process.env.REACT_APP_API_URL}cart/sendemail`, { orderid: responsecart.data.buyOrder, name: responsecart.data.user_name, lastname: responsecart.data.user_lastname, email: responsecart.data.user_email })
                const sendEnvio = await axios.post(`https://webhook.wareclouds.app/wareclouds`, data, {
                    headers: {
                        'id-ecommerce': 'simplesoluciones',
                        'hook-api-token': '7ad57847-cd44-4235-a70c-1eb0446802fa'
                    }
                })

            }
            if (responsecart.data.datalayer !== true) {
                const createDatalayer = await dataLayer(responsecart.data)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const dataLayer = async (data) => {
        if (data.cart_status == 'completed') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'purchase',
                currency: 'CLP',
                value: data.total,
                transaction_id: data.buyOrder,
                affiliation: 'Online Store',
                items: [{
                    item_name: data.products.name,
                    item_price: data.total,
                    quantity: 1,
                }],
                transactionDate: new Date(data.createdAt).toLocaleString("es-ES", { month: "short", day: "2-digit", year: 'numeric' }),
                email: data.user_email,
            });
            const updateDatalayer = await axios.post(`${process.env.REACT_APP_API_URL}cart/datalayer`, { token: data.token, })
        }
    }




    return (
        <div className='text-[#000] h-screen mx-auto flex flex-col justify-center items-center'>
            {
                loading == false ?
                    operationStatus == 'completed' ?
                        <div className='flex flex-col justify-center items-center p-5 text-center'>
                            <img src={mano} alt='mano' className='w-[150px] h-[150px]' />
                            <h1 className='text-7xl text-[#D00070] font-bold leading-normal'>¡Pago exitoso!</h1>
                            <p className='text-[#000] text-lg'>{`Se enviará un email con tu boleta a ${cart?.user_email}.`}</p>
                            <div className='text-[#545F71] mt-5 text-center flex flex-col gap-5'>
                                <p className='text-4xl font-semibold'>{cart?.products.name}</p>
                                <p className='text-5xl font-bold'>{`$${cart?.total}`}</p>
                                <div>
                                    <p>{`Fecha: ` + new Date(cart?.createdAt).toLocaleString("es-ES", { month: "short", day: "2-digit", year: 'numeric' })}</p>
                                    <p>{`Transaccion: ${cart?.buyOrder}`}</p>
                                </div>
                                <a className='bg-[#D00070] text-white rounded-xl p-4 ' href='/'>
                                    Volver al Inicio
                                </a>
                            </div>
                        </div>
                        :
                        <div className='flex flex-col gap-5 p-4 text-center'>
                            <h1 className='text-8xl text-[#D00070] font-bold leading-normal'>¡Pago Fallido!</h1>
                            <p className='text-[#000] text-lg'>No se pudo realizar el pago, por favor intente nuevamente.</p>
                            <a className='bg-[#D00070] text-white rounded-xl p-4 text-center' href='/'>
                                Volver al Inicio
                            </a>
                        </div>

                    :
                    <>
                        <TailSpin
                            height="100"
                            width="100"
                            color="#D00070"
                            ariaLabel="tail-spin-loading"
                            radius="2"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </>

            }
        </div>
    )
}

export default CompraStatus