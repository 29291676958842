
import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Formulario from '../../Sections/Formulario';
import Snowfall from 'react-snowfall';


function EmprendeDetail() {
    const shopRef = useRef()
    const handleClickGoToPay = () => {
        shopRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className="text-white">
            <Snowfall snowflakeCount={90} />
            <div className='md:block w-full h-min md:h-screen bg-navidad-mpos mx-auto overflow-hidden bg-cover bg-no-repeat md:bg-repeat  md:bg-contain '>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] h-full mx-auto flex flex-col md:flex-row w-full justify-center items-center py-20'>
                    <div className='flex flex-col gap-5 2xl:gap-10  p-5 md:p-0'>
                        <h1 className='font-bold text-5xl md:text-6xl 2xl:text-8xl text-center md:text-left md:leading-[90px] '>¡Pack Emprende<br></br> con todo!</h1>
                        <p className='text-lg md:text-left text-center'>Lleva Gratis tu link de pago con tu pack EMPRENDE</p>
                        <p className='2xl:text-9xl md:text-8xl text-8xl font-bold text-center md:text-left'>$9.990</p>
                        {/* <small className='text-left text-lg text-[#6D2077]'>Valor único por equipo + IVA</small> */}
                        <div className='flex gap-5 self-center md:self-start'>
                            <button onClick={() => handleClickGoToPay()} className=' btn bg-white text-sm  text-[#6D2077] px-10 hover:bg-[#6D2077] hover:text-white hover:border-white border'>¡La Quiero!</button>
                        </div>
                    </div>
                    <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Transbank/group_6_4x.webp'} className='2xl:w-[500px] md:w-[400px]' />
                </div>
            </div>
            <div className='md:block w-full h-min bg-[#BBC7CD] mx-auto'>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto flex flex-col md:flex-row justify-between md:items-center md:py-14 p-5 md:px-0 w-full gap-5 md:gap-0'>
                    <h3 className='font-bold 2xl:text-4xl md:text-3xl text-4xl md:text-left'>¡Conoce todos los<br></br> beneficios!</h3>
                    <div className='flex flex-col justify-center items-center gap-5'>
                        <p className='text-md text-center md:w-96'><span className='font-bold'>Agilizas formas de pago</span>: Entrega mayor flexibilidad en la compra, gracias a todas las alternativas que ofrecen las tarjetas de débito, crédito y prepago.</p>
                    </div>
                </div>
            </div>
            <div ref={shopRef} className='bg-white w-full h-min py-14 p-5 md:py-14'>
                <div className='2xl:max-w-[1200px] md:max-w-[1000px] w-full mx-auto flex items-center justify-start bg-white'>
                    <Formulario id={9} nombre={'Pack Emprende'} />
                </div>
            </div>
        </div>
    );
};



export default EmprendeDetail